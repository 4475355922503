import {
  DocumentData,
  collection,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import "./MyReadsPage.scss";
import { BookCard } from "./components/BookCard/BookCard";
import { BookOfTheMonth } from "./components/BookOfTheMonth/BookOfTheMonth";

type MyReadsPageProps = {};

export const MyReadsPage = (props: MyReadsPageProps) => {
  const [currentlyReadingList, setCurrentlyReadingList] = useState<
    DocumentData[]
  >([]);
  const [readList, setReadList] = useState<DocumentData[]>([]);

  useEffect(() => {
    fetchMyReads();
  }, []);

  const fetchMyReads = async () => {
    const db = getFirestore();
    const res = (await getDocs(collection(db, "myreads"))).docs.map((docs) => {
      const result = docs.data();
      result.id = docs.id;
      return result;
    });
    const doneReadingBooks: DocumentData[] = [];
    const currentlyReadingBooks: DocumentData[] = [];

    res.forEach((book) => {
      if (book["is_reading"]) {
        currentlyReadingBooks.push(book);
      } else {
        doneReadingBooks.push(book);
      }
    });
    setCurrentlyReadingList(currentlyReadingBooks);
    setReadList(doneReadingBooks.slice(0, 10));
  };

  return (
    <>
      <div className="myreads-page-outermost-div">
        <h1 className="myreads-page-heading">My Reads.</h1>
        <BookOfTheMonth />
        <div className="reading-list-section">
          <h2>Currently Reading. 📖</h2>
          <div className="myreads-page-books-list">
            {currentlyReadingList.map((bookDetails) => (
              <BookCard
                key={bookDetails.id}
                thumbnail={bookDetails.thumbnail}
                author={bookDetails.author}
                title={bookDetails.title}
              />
            ))}
          </div>
        </div>
        <div className="reading-list-section">
          <h2>Done Reading. ✅</h2>
          <div className="myreads-page-books-list">
            {readList.map((bookDetails) => (
              <BookCard
                key={bookDetails.id}
                thumbnail={bookDetails.thumbnail}
                author={bookDetails.author}
                title={bookDetails.title}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
