import "./AboutMeHeading.scss";

type AboutMeHeadingProps = {};

export const AboutMeHeading = (props: AboutMeHeadingProps) => {
  return (
    <div className="about-me-heading-div">
      <h1>A</h1>
      <h1 className="letter-B">B</h1>
      <h1>OUT HARSH</h1>
    </div>
  );
};
