import React from "react";
import ContactMePNG from "../../../../../assets/contact_me_illustration.png";
import "./SocialGraph.scss";

type SocialGraphProps = {};

export const SocialGraph = (props: SocialGraphProps) => {
  return (
    <div className="social-graph-div">
      <img
        className="social-graph-img"
        src={ContactMePNG}
        alt="Social Graph Containing Social Media Links"
      />
    </div>
  );
};
