import "./BookCard.scss";

type BookCardProps = {
  title: string;
  thumbnail: string;
  author: string;
};

export const BookCard = (props: BookCardProps) => {
  return (
    <>
      <div className="book-card">
        <img
          className="book-card-thumbnail"
          src={props.thumbnail}
          alt={props.title + " by " + props.author}
        />
        {/* <p className="book-card-title-and-author">
          <span>{props.title}</span> by <span>{props.author}</span>
        </p> */}
      </div>
    </>
  );
};
