import React from "react";
import "./HeroSection.scss";
import { LeftHeroSection } from "./LeftHeroSection/LeftHeroSection";
import { RightHeroSection } from "./RightHeroSection/RightHeroSection";

type HeroSectionProps = {};

export const HeroSection = (props: HeroSectionProps) => {
  return (
    <section className="hero-section">
      <LeftHeroSection />
      <RightHeroSection />
    </section>
  );
};
