import "./BlogCard.scss";

type BlogCardProps = {
  title: string;
  date: string;
  thumbnail: string;
  description: string;
  url: string;
};

export const BlogCard = (props: BlogCardProps) => {
  const formatBlogDescription = (description: string): string => {
    let words = description.split(" ");
    let noOfWords: number = words.length;
    if (noOfWords <= 24) return description + "...";
    return words.slice(0, 25).join(" ") + "...";
  };

  return (
    <div className="blog-card">
      <a className="title-link" href={props.url} target="_blank" rel="noreferrer">
        <h1 className="blog-card-title">{props.title}</h1>
      </a>
      <small className="blog-card-published-date">{props.date}</small>
      <img src={props.thumbnail} alt="" className="blog-card-thumbnail" />
      <div className="blog-card-description">
        {formatBlogDescription(props.description)}
      </div>
      <a className="cta-read-more" href={props.url} target="_blank" rel="noreferrer">
        <button className="blog-card-read-more-btn">Read More</button>
      </a>
    </div>
  );
};
