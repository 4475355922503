import AboutMePNG from "../../../../assets/profile_pics/profile-pic-3.png";
import { AboutMeHeading } from "./AboutMeHeading/AboutMeHeading";
import "./AboutMeSection.scss";

type AboutMeSectionProps = {};

export const AboutMeSection = (props: AboutMeSectionProps) => {
  return (
    <section className="about-me-section">
      <AboutMeHeading />
      <p className="about-me-text">
        Harsh is a software developer with a problem solving attitude.
        Currently, he is working at Siemens Digital Industries Software. He
        graduated from Visvesvaraya National Institute of Technology, Nagpur.
        His work takes a user-centered approach to problem-solving through
        engaging storytelling.
      </p>
      <img
        src={AboutMePNG}
        alt="Man with a cap Illustration"
        className="about-me-img"
        style={{ scale: "2.4" }}
      />
    </section>
  );
};
