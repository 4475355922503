import { Route, Routes } from "react-router-dom";
import { BlogPage } from "./pages/BlogPage/BlogPage";
import { HomePage } from "./pages/HomePage/HomePage";
import { MyReadsPage } from "./pages/MyReadsPage/MyReadsPage";
import { ProjectsPage } from "./pages/ProjectsPage/ProjectsPage";
import { Footer } from "./shared/components/Footer/Footer";
import { Header } from "./shared/components/Header/Header";
import { PageNOTFound } from "./shared/components/PageNOTFound/PageNOTFound";

type RoutesProps = {};

export const RoutesHandler = (props: RoutesProps) => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/myreads" element={<MyReadsPage />} />
        <Route path="*" element={<PageNOTFound />} />
      </Routes>
      <Footer />
    </>
  );
};
