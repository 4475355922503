import { ProjectCard } from "./components/ProjectCard/ProjectCard";
import "./ProjectsPage.scss";

type ProjectsPageProps = {};

export const ProjectsPage = (props: ProjectsPageProps) => {
  return (
    <>
      <div className="projects-page-outermost-div">
        <h1 className="projects-page-heading">PROJECTS</h1>
        <div className="projects-page-projects-list">
          <ProjectCard
            thumbnailUrl="https://ph-files.imgix.net/c4f6aa62-eaec-4c31-9f6c-f86233e89c2c.png?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=800&h=400&fit=crop&dpr=2"
            title="Blissful Backdrop."
            description="Windows Wallpaper Application."
            url="https://www.producthunt.com/products/blissful-backdrop/"
            tags={["flutter", "dart", "desktopapp"]}
          />
          <ProjectCard
            thumbnailUrl="https://i.ibb.co/cFB2jXD/volume.jpg"
            title="Volume."
            description="Creating the future of content."
            url="https://www.volumestories.com/"
            tags={["flutter", "dart", "mobileapp"]}
          />
          <ProjectCard
            thumbnailUrl="https://i.ibb.co/fQLTWz0/default-1.png"
            title="Boxer"
            description="Utility logging tool for dart & flutter applications to print log messages with useful meta-data"
            url="https://pub.dev/packages/boxer"
            tags={["dart", "utility"]}
          />
          <ProjectCard
            thumbnailUrl="https://i.ibb.co/mv8shbh/default-2.jpg"
            title="Custom Upgrader"
            description="Flutter package for prompting users to upgrade when there is a newer version of the app in the store."
            url="https://pub.dev/packages/custom_upgrader"
            tags={["flutter", "dart"]}
          />
          <ProjectCard
            thumbnailUrl="https://i.ibb.co/LkCWSKQ/react-cli.png"
            title="React CLI"
            description="A command line utility for react applications"
            url="https://www.npmjs.com/package/react-cli-utility"
            tags={["javascript", "utility", "commandline"]}
          />
          <ProjectCard
            thumbnailUrl="https://i.ibb.co/86PPKB6/dr-covid.png"
            title="Dr. COVID"
            description="An android application that helps the users to track the covid updates in a user friendly interface along with it's trending videos, trending news and COVID-19 Analyzer features."
            url="https://github.com/hvg2416/DR-COVID"
            tags={["mobileapp", "android", "java"]}
          />
        </div>
      </div>
    </>
  );
};
