import { ContactForm } from "./ContactForm/ContactForm";
import "./ContactMeSection.scss";
import { SocialGraph } from "./SocialGraph/SocialGraph";

type ContactMeSectionProps = {};

export const ContactMeSection = (props: ContactMeSectionProps) => {
  return (
    <section className="contact-me-section" id="contact-me">
      <h1 className="contact-me-section-header-text-1">Let's Connect.</h1>
      <div className="contact-me-section-form-and-social-links-row">
        <SocialGraph />
        <ContactForm />
      </div>
    </section>
  );
};
