import gsap from "gsap";
import React, { useEffect } from "react";
import { AboutMeSection } from "./components/AboutMeSection/AboutMeSection";
import { ContactMeSection } from "./components/ContactMeSection/ContactMeSection";
import { FeaturedSection } from "./components/FeaturedSection/FeaturedSection";
import { HeroSection } from "./components/HeroSection/HeroSection";
import { WorkCycleSection } from "./components/WorkCycleSection/WorkCycleSection";
import "./HomePage.scss";

type HomePageProps = {};

export const HomePage = (props: HomePageProps) => {
  useEffect(() => {
    gsap.from("body", {
      opacity: 0,
      duration: "1",
    });
  });

  return (
    <React.Fragment>
      <HeroSection />
      <WorkCycleSection />
      <AboutMeSection />
      <FeaturedSection />
      <ContactMeSection />
    </React.Fragment>
  );
};
