import { Chip } from "../../../../shared/components/Chip/Chip";
import "./ProjectCard.scss";

type ProjectCardProps = {
  thumbnailUrl: string;
  title: string;
  description: string;
  url: string;
  tags: Array<string>;
};

export const ProjectCard = (props: ProjectCardProps) => {
  console.log(props);
  return (
    <div
      className="project-card"
      style={{ backgroundImage: `url(${props.thumbnailUrl})` }}
    >
      <div className="project-card-overlay">
        <a
          href={props.url}
          target="_blank"
          rel="noreferrer"
          className="project-card-info"
        >
          <h3 className="project-card-title">{props.title}</h3>
          <p className="project-card-description">{props.description}</p>
          {props.tags.map((tag, index) => (
            <Chip key={index} tag={tag} />
          ))}
        </a>
      </div>
    </div>
  );
};
