import "./Header.scss";

type HeaderProps = {};

export const Header = (props: HeaderProps) => {
  return (
    <header>
      <div className="header">
        <a className={`nav-link`} href="/">
          Home
        </a>
        <div className="nav-links-row">
          <a
            className={`nav-link ${
              window.location.pathname === "/projects"
                ? "nav-link-selected"
                : ""
            }`}
            href="/projects"
          >
            Projects
          </a>
          <a
            className={`nav-link ${
              window.location.pathname === "/blog" ? "nav-link-selected" : ""
            }`}
            href="/blog"
          >
            Blog
          </a>
          <a className={`nav-link`} href="/#contact-me">
            Contact
          </a>
        </div>
      </div>
    </header>
  );
};
