import "./BlogPage.scss";
import { BlogCard } from "./components/BlogCard/BlogCard";

type BlogPageProps = {};

export const BlogPage = (props: BlogPageProps) => {
  return (
    <>
      <div className="blog-page-outermost-div">
        <h1 className="blog-page-heading">BLOG</h1>
        <div className="blog-page-blogs-list">
          <BlogCard
            title="Simplify Code Refactoring with UML Diagrams: Introducing @hvg24/umljs"
            date="17 October, 2023"
            description="Simplify Code Refactoring with UML Diagrams: Introducing @hvg24/umljs"
            thumbnail="https://miro.medium.com/v2/resize:fit:720/format:webp/1*EskWFB5OnFmgHjkvdrnnMw.png"
            url="https://medium.com/@hvg24/simplify-code-refactoring-with-uml-diagrams-introducing-hvg24-umljs-c219a9c80677"
          />
          <BlogCard
            title="Understanding web components - Part 3 (Shadow DOM)"
            date="10 September, 2023"
            description="Welcome back to the third part of our series on Understanding Web Components! Firstly, I would like to congratulate you because your…"
            thumbnail="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*d91JaSkjumiiqaDP.png"
            url="https://hvg24.medium.com/understanding-web-components-shadow-dom-part-3-da1e6904e270"
          />
          <BlogCard
            title="Understanding web components — Part 2"
            date="8 July, 2023"
            description="Welcome back! I hope you found Part 1 of this series on web components informative and helpful. In Part 2, we’ll be taking things to the…"
            thumbnail="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*d91JaSkjumiiqaDP.png"
            url="https://medium.com/@hvg24/understanding-web-components-part-2-730de81c7cb7"
          />
          <BlogCard
            title="Understanding web components — Part 1"
            date="13 May, 2023"
            description="Have you ever found yourself spending countless hours writing the same code over and over again to create custom UI components for your web…"
            thumbnail="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*d91JaSkjumiiqaDP.png"
            url="https://hvg24.medium.com/understanding-web-components-part-1-4e88316c71b6"
          />
          <BlogCard
            title="How to handle monolith repositories using git sparse checkout? (Git)"
            date="25 February, 2023"
            description="Have you ever worked on a monolith project? If yes, you may have already faced a situation where you're working on one particular part of…"
            thumbnail="https://miro.medium.com/v2/resize:fit:1244/format:webp/1*cAnizQKh2gxoixEkXArROw.png"
            url="https://hvg24.medium.com/how-to-handle-monolith-repositories-using-git-sparse-checkout-git-11f2298d0d16"
          />
          <BlogCard
            title="How to add github hosted package as dependency in pubspec.yml? (Flutter)"
            date="18 February, 2023"
            description="If you're here reading the article, it's likely that you have some flutter package hosted on github or any other service. And you want to…"
            thumbnail="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*YwT4yLJHMZ5IEr82HQlUyQ.png"
            url="https://hvg24.medium.com/how-to-add-github-hosted-package-as-dependency-in-pubspec-yml-flutter-1c1847f5a962"
          />
          <BlogCard
            title="How to create a docker image to run puppeteer? (Docker)"
            date="11 February, 2023"
            description="Have you ever used puppeteer? If you have, then you may also faced a situation which is like, you're working on your local environment…"
            thumbnail="https://miro.medium.com/max/720/1*j85heuQoT54uUcjTKl7KtQ.webp"
            url="https://hvg24.medium.com/how-to-create-a-docker-image-to-run-puppeteer-docker-202a0269f1cd"
          />
          <BlogCard
            title="Adding logs in production environment on frontend using debug"
            date="04 February, 2023"
            description="Ever seen how crucial the logs are for a software application? And in particular, you can easily see for every decent server side"
            thumbnail="https://miro.medium.com/v2/resize:fit:720/format:webp/0*7OLiwh8ScN2g-g0V.png"
            url="https://hvg24.medium.com/adding-logs-in-production-environment-on-frontend-using-debug-f783a0e014"
          />
          <BlogCard
            title="Architecting Network Layer in a flutter application (Scalability)"
            date="29 January, 2023"
            description="Today, we'll see how we can architect network layer for a flutter application. Doing this will help the application to scale better."
            thumbnail="https://miro.medium.com/max/1400/1*u_K_fAxD4UvNKkyuTNoupA.gif"
            url="https://hvg24.medium.com/architecting-network-layer-in-a-flutter-application-scalability-60c86c5638ca"
          />
          <BlogCard
            title="Understanding an API Rate limiter (System Design)"
            date="21 January, 2023"
            description="Let's understand system design of an API rate limiter using Token Bucket Algorithm. I promise you, it'll be a fun learning."
            thumbnail="https://miro.medium.com/max/720/1*VEpNgICatFsKV2IBHVjb5Q.webp"
            url="https://hvg24.medium.com/understanding-an-api-rate-limiter-system-design-bfab7f304a99"
          />
          <BlogCard
            title="Using SharedPreferences in a better way (Flutter)"
            date="14 January, 2023"
            description="If you already came to stop by this article, you must be someone who has already worked in flutter, right?"
            thumbnail="https://miro.medium.com/max/1400/0*8lddFayVwCtj9k0t.webp"
            url="https://hvg24.medium.com/using-sharedpreferences-in-a-better-way-flutter-a8f84880238e"
          />
          <BlogCard
            title="Render Cube using Planes geometry in Threejs"
            date="7 January, 2023"
            description="If you're looking the answer for how to render cube using planes to have more control over each face of the cube. This article will give the answer to the same."
            thumbnail="https://i.ibb.co/tDZmp1d/Screenshot-2023-01-07-at-8-14-59-PM.png"
            url="https://hvg24.medium.com/render-cube-using-planes-geometry-in-threejs-7fd55e0b08c6"
          />
          <BlogCard
            title="How to use an android device for development wirelessly?"
            date="27 October, 2021"
            description="Ever got a chance to develop android apps? Do you know the hassle of brainstorming & coding your app in full speed & suddenly, your device got disconnected & rest is … :’(
      Don’t worry. I also went through the same experience and decided to find a bypass route to"
            thumbnail="https://i.ibb.co/2c9hMjY/blog1.jpg"
            url="https://hvg24.medium.com/how-to-use-an-android-device-for-development-wirelessly-7e6fc08c7438"
          />
          <BlogCard
            title="How to use Postgres database on Heroku?"
            date="26 October, 2021"
            description="First of all, create a new app on heroku"
            thumbnail="https://i.ibb.co/rM6rTZS/blog2.png"
            url="https://medium.com/geekculture/how-to-use-postgres-database-on-heroku-bfe9431dd18d"
          />
        </div>
      </div>
    </>
  );
};
