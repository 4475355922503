import "font-awesome/css/font-awesome.min.css";

const RatingBar = ({ rating }: any) => {
  // Round the rating to the nearest 0.5 to determine the number of filled and half-filled stars.
  const roundedRating = Math.round(rating * 2) / 2;

  // Create an array to store the star elements.
  const stars = [];

  // Loop through and create the star elements.
  for (let i = 1; i <= 5; i++) {
    if (i <= roundedRating) {
      // Full star
      stars.push(<i key={i} className="fa fa-star"></i>);
    } else if (i - 0.5 === roundedRating) {
      // Half star
      stars.push(<i key={i} className="fa fa-star-half-o"></i>);
    } else {
      // Empty star
      stars.push(<i key={i} className="fa fa-star-o"></i>);
    }
  }

  return (
    <div className="star-rating" title={`Goodreads rating ${rating}`}>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  );
};

export default RatingBar;
