import React from "react";
import { Link } from "react-router-dom";
import "./FeaturedSection.scss";

type FeaturedSectionProps = {};

export const FeaturedSection = (props: FeaturedSectionProps) => {
  return (
    <div className="featured-section">
      <Link to={"projects"} className="featured-banner">
        <img
          src="https://cdn.dribbble.com/users/350660/screenshots/9912509/media/4e41a111561a01fbf733571adb6c441f.jpg"
          alt=""
        />
        <div className="featured-banner-overlay">
          <h3>PROJECTS</h3>
        </div>
      </Link>
      <Link to={"blog"} className="featured-banner">
        <img
          src="https://cdn.dribbble.com/users/350660/screenshots/15088418/media/38f8b48efcfa373144a6d392fcaec347.jpg"
          alt=""
        />
        <div className="featured-banner-overlay">
          <h3>BLOG</h3>
        </div>
      </Link>
    </div>
  );
};
