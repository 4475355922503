import React from "react";
import WorkCycleSVG from "../../../../assets/work_cycle.svg";
import "./WorkCycleSection.scss";

type WorkCycleSectionProps = {};

export const WorkCycleSection = (props: WorkCycleSectionProps) => {
  return (
    <section className="work-cycle-section">
      <img id="work-cycle-section-img" src={WorkCycleSVG} alt="work cycle" />
    </section>
  );
};
