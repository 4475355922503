import { addDoc, collection, getFirestore } from "firebase/firestore";
import React, { useState } from "react";
import { CircularProgressIndicator } from "../../../../../shared/components/CircularProgressIndicator/CircularProgressIndicator";
import "./ContactForm.scss";

type ContactFormProps = {};

export const ContactForm = (props: ContactFormProps) => {
  const [emailFormFieldValue, setEmailFormFieldValue] = useState("");
  const [messageFormFieldValue, setMessageFormFieldValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const verifyEmail = () => {
    if (
      emailFormFieldValue !== "" &&
      /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
        emailFormFieldValue
      )
    ) {
      setIsEmailValid(true);
      return true;
    } else {
      setIsEmailValid(false);
      return false;
    }
  };

  const sendFormData: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (verifyEmail()) {
        const db = getFirestore();
        await addDoc(collection(db, "users"), {
          emailFormFieldValue,
          messageFormFieldValue,
        });
        setEmailFormFieldValue("");
        setMessageFormFieldValue("");
      }
    } catch (error) {
      console.error("Error adding document: ", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form-div">
      <form action="#" className="contact-form">
        <label htmlFor="email-form-field">Email</label>
        <input
          className="contact-form-field"
          type="email"
          name="email"
          id="email-form-field"
          placeholder="Email"
          value={emailFormFieldValue}
          onChange={(e) => {
            setEmailFormFieldValue(e.target.value);
            verifyEmail();
          }}
        />
        {!isEmailValid ? (
          <small className="form-field-error-text">
            Please enter a valid email id.
          </small>
        ) : null}
        <label htmlFor="message-form-field">Message</label>
        <textarea
          className="contact-form-field"
          name="message"
          id="message-form-field"
          cols={30}
          rows={7}
          placeholder="Type your message here..."
          value={messageFormFieldValue}
          onChange={(e) => setMessageFormFieldValue(e.target.value)}
        ></textarea>
        <button
          id="contact-form-submit-btn"
          type="submit"
          onClick={sendFormData}
        >
          {loading ? <CircularProgressIndicator /> : "Send"}
        </button>
      </form>
    </div>
  );
};
