import {
  AiFillLinkedin,
  AiOutlineGithub,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineReddit,
} from "react-icons/ai";
import { SiPeerlist, SiProducthunt } from "react-icons/si";
import "./Footer.scss";

type FooterProps = {};

export const Footer = (props: FooterProps) => {
  return (
    <footer>
      <div className="footer">
        <p>
          Made with <span className="footer-heart-icon">♥</span> by Harsh
        </p>
        <div className="social-links-div">
          <a
            title="Instagram"
            href="https://www.instagram.com/dynamic_shade/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <AiOutlineInstagram className="social-link-icon" />
          </a>
          <a
            title="LinkedIn (hvg2416)"
            href="https://www.linkedin.com/in/hvg24/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <AiFillLinkedin className="social-link-icon" />
          </a>
          <a
            title="Reddit (hvg2416)"
            href="https://www.reddit.com/user/hvg2416"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <AiOutlineReddit className="social-link-icon" />
          </a>
          <a
            title="Github (hvg2416)"
            href="https://github.com/hvg2416"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <AiOutlineGithub className="social-link-icon" />
          </a>
          <a
            title="Gmail"
            href="mailto: hvgautam24.vnit@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <AiOutlineMail className="social-link-icon" />
          </a>
          <a
            title="Peerlist"
            href="https://peerlist.io/harshgautam/about"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <SiPeerlist className="social-link-icon" />
          </a>
          <a
            title="Product Hunt"
            href="https://www.producthunt.com/@dynamic_developer"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <SiProducthunt className="social-link-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};
