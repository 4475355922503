import {
  DocumentData,
  collection,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import BuyOnAmazonButton from "../../../../shared/components/BuyOnAmazon/BuyOnAmazon";
import { Chip } from "../../../../shared/components/Chip/Chip";
import RatingBar from "../../../../shared/components/RatingBar/RatingBar";
import { BookCard } from "../BookCard/BookCard";
import "./BookOfTheMonth.scss";

export const BookOfTheMonth = () => {
  const [bookOfTheMonth, setBookOfTheMonth] = useState<DocumentData>({});

  useEffect(() => {
    fetchBookOfTheMonth();
  }, []);

  async function fetchBookOfTheMonth() {
    const db = getFirestore();
    const res = (await getDocs(collection(db, "book-of-the-month"))).docs.map(
      (docs) => {
        const result = docs.data();
        result.id = docs.id;
        return result;
      }
    );
    setBookOfTheMonth(res[0]);
  }

  return (
    <div className="book-of-the-month-outer-div">
      <h1>Book of the Month</h1>
      <div className="book-details-outer-div">
        {bookOfTheMonth.title ? (
          <>
            <div className="book-details-div">
              <h3>
                {bookOfTheMonth.title} by {bookOfTheMonth.author}
              </h3>
              <div className="rating-bar-div">
                <RatingBar rating={bookOfTheMonth.goodreads_rating} />
              </div>
              <span>
                {bookOfTheMonth.tags
                  ? bookOfTheMonth.tags.map((tag: string) => (
                      <Chip key={tag} tag={tag} />
                    ))
                  : null}
              </span>
              <p>{bookOfTheMonth.summary}</p>
              <BuyOnAmazonButton link={bookOfTheMonth.affiliate_link} />
            </div>
            <div className="book-thumbnail-div">
              <BookCard
                thumbnail={bookOfTheMonth.thumbnail}
                author={bookOfTheMonth.author}
                title={bookOfTheMonth.title}
              />
            </div>
          </>
        ) : (
          <h4>Loading...</h4>
        )}
      </div>
    </div>
  );
};
