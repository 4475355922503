import "./BuyOnAmazon.scss";

const BuyOnAmazonButton = ({ link }: any) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="amazon-button"
    >
      <img
        src="https://cdn4.iconfinder.com/data/icons/social-media-2146/512/31_social-512.png"
        alt="Amazon Icon"
        className="amazon-icon"
      />
      Buy Now on Amazon
    </a>
  );
};

export default BuyOnAmazonButton;
